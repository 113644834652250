const settings = {
    'title': 'COVID FinAid Appeal'
}

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    settings['api_url'] = "http://caye.shelms.io:8001";
} else {
    // production code
    settings['api_url'] = "https://covaid-api.bioinformatix.io";
}


export default settings;
