import React, { Component } from "react";
import { connect } from "react-redux";

// import logo from "./logo.svg";
import "../../App.css";
import styles from "./AidApp.module.css";
import BigHeader from "../../components/BigHeader";
import UploadForm from "../../components/UploadForm";
import Report from "../../components/Report";

import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../store/actions/index';
import axios from '../../axios-instance';

class AidApp extends Component {
  render() {
    return (
      <div className={styles.container}>
        <BigHeader />
        <UploadForm />
        <Report token={this.props.token} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetAuthRedirectPath: (path) =>
      dispatch(actions.setAuthRedirectPath(path)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(AidApp, axios));
