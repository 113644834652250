import { FETCH_FILES } from "../actions/files";

const initialState = {
  allFiles: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FILES:
      return {
        allFiles: action.allFiles,
      };
    default:
      return state;
  }
};
