import React from "react";

import settings from "../constants/settings";
import styles from './BigHeader.module.css';
import appLogo from '../assets/logo.png';

const BigHeader = (props) => {
    return (
      <div className={styles.header}>
        <div>
          <img alt={settings.title} src={appLogo} width="200" />
        </div>
        <div>
          <h1 className={styles.title}>{settings.title}</h1>
        </div>
      </div>
    );
}

export default BigHeader;
